@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

img {
  max-width: 100%;
}

.header {
  background-color: #00143d;
  display: flex;
  flex-direction: row;
  padding: 0 3%;
  align-items: center;
}

.logo {
  max-width: 20%;
}

.menu-contenedor {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-around;
}

a.menu-resaltado {
  color: #00143d !important;
  background-color: #ffb300;
  padding: 8px 20px;
  border-radius: 120px;
  font-weight: 900 !important;
  font-size: 20px !important;
  display: flex;
}

.banner {
  background-color: #00143d;
  padding: 0 4%;
  display: flex;
}

.banner-izq {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-der {
  width: 50%;
}

p,
h1,
h2 {
  margin: 0;
}

.saludo {
  font-weight: 500;
  font-size: 20px;
}

.nombre {
  font-weight: 800;
  font-size: 50px;
}

.resaltado {
  color: #ffb300;
}

.subtitulo {
  font-size: 23px;
}

.presentacion {
  border-left: 1px solid white;
  padding: 4px 0 5px 20px;
  margin: 15px 0 0 5px;
  font-size: 18px;
}

.formacion {
  padding: 135px 4%;
  color: white;
  background-color: #00143d;
}

.formacion-uno .nombre,
.formacion-uno .presentacion {
  text-align: center;
  border: none !important;
}

.cincuenta {
  width: 50%;
}
.treinta {
  width: 30%;
}
.setenta {
  width: 70%;
}

.sesenta {
  width: 60%;
}

.tercio {
  width: 30%;
}

.cuarenta {
  width: 40%;
}

.fondo {
  background-color: #000d23;
  padding: 20px 25px;
  border-radius: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 30px;
}

.formacion-dentro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 17px;
}

.subtitulo-izquierda {
  margin: 40px 0;
  font-weight: 800;
  font-size: 50px;
}

.border-arriba {
  border-top: 1px solid white;
}
.espacio-arriba {
  padding-top: 15px !important;
}

.border-abajo {
  border-bottom: 1px solid white;
}
.espacio-abajo {
  padding-bottom: 15px !important;
}

.formacion-segunda-seccion {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.formacion-segunda-seccion .sesenta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.compromiso {
  padding: 135px 4%;
  color: white;
  background-color: #000d23;
}

.compromiso-tercio {
  display: flex;
  justify-content: space-evenly;
  margin: 40px 0;
}

.centrar-texto {
  text-align: center;
}

.derecha-texto {
  text-align: right;
}

.tercio-fondo {
  background-color: #000d23;
  border-radius: 10px;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
}

.img-treinta {
  max-width: 40%;
}

.compromiso-dos {
  display: flex;
  flex-direction: row;
  padding: 0 10%;
}

.subtitulo-grande {
  font-size: 40px;
}

.parrafo-compromiso {
  font-size: 20px;
  font-weight: 300;
}

.centrado-todo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.empresas {
  padding: 135px 4%;
  color: white;
  background-color: #00143d;
}

.empresas-dos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-evenly;
  padding: 45px 4%;
}

.empresas-dos .cuarenta {
  background-color: #000d23;
  padding: 50px 45px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: top;
}

.parrafo-empresas {
  position: relative; /* Necesario para posicionar el dot correctamente */
  padding-left: 20px; /* Espacio para el dot */
  margin-top: 20px;
}

.parrafo-empresas::before {
  content: "•"; /* Agrega el dot como contenido */
  position: absolute;
  left: 0; /* Posición a la izquierda del párrafo */
  top: 10%; /* Centrado vertical */
  transform: translateY(-50%); /* Ajusta el centrado vertical */
  color: #ffb300; /* Color del dot */
  font-size: 3rem; /* Tamaño del dot */
}

.parrafo-empresas.lista {
  margin-top: 5px;
}

.parrafo-empresas.lista::before {
  top: 35%; /* Centrado vertical */
}

.sigueme {
  padding: 135px 4%;
  color: white;
  background-color: #000d23;
}

.logo-footer {
  max-width: 30%;
}

.sigueme.centrado-todo {
  align-items: center;
}

.parrafo-sigueme {
  font-size: 30px;
}

.redes-footer {
  max-width: 5%;
}

.contenedor-redes-footer {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.izquierda-texto.parrafo-compromiso,
.compromiso-dos .subtitulo.izquierda-texto {
  margin-right: 50px;
}

.contenedor-redes-footer a {
  display: contents;
}

.img-empresas {
  max-width: 90%;
}

.centrar {
  display: flex;
  justify-content: center;
}

.imagenmenu {
  max-width: 35px;
}

.responsive {
  display: none;
}

.contenedor_propuestas {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000d23;
  padding: 135px 15% 0 15%;
}

.propuesta_grande {
  background-color: #000d23;
}

.contenedorpropuesta {
  display: flex;
  flex-direction: row;
  gap: 70px;
}

.titulopropuesta1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  margin: 0 0 -15px 0;
}

.titulopropuesta2 {
  color: #ffb300;
  font-size: 60px;
  font-weight: 900;
  text-align: center;
  margin: 0 0 20px 0;
}

.contenedor_propuestas h3 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10px;
  font-style: italic;
  font-size: 21px;
}

.contenedor_propuestas p {
  color: #fff;
}

.contenedorpropuestaizquierda {
  display: flex;
  flex-direction: row;
}

.contenedorpropuestaderecha {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cinco {
  width: 10%;
  text-align: center;
}

.noventaycinco {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.img_numero {
  width: 60%;
}

.img_propu {
  width: 80%;
  margin-top: 10px;
}

.texto_propu {
  font-size: 19px;
}

.contenedorpropuestaderecha .resaltado {
  font-size: 21px;
}

.contenedorpropuesta li {
  color: #fff;
  font-size: 19px;
  margin: 8px 0;
}
.contenedorpropuesta ul {
  margin: 0;
}

.separador {
  display: flex;
  background-color: #00273f;
  height: 3px;
  margin: 20px 0;
  width: -webkit-fill-available;
}

/* Responsive */
@media (max-width: 768px) {
  .logo {
    max-width: 50%;
  }
  .menu-contenedor {
    max-width: 50%;
  }

  .banner,
  .empresas-dos,
  .compromiso-tercio,
  .compromiso-dos,
  .formacion-dentro,
  .formacion-segunda-seccion {
    flex-direction: column;
  }
  .formacion,
  .compromiso,
  .empresas,
  .sigueme {
    padding: 40px 0px;
  }
  .nombre {
    font-size: 30px;
  }
  .banner-izq,
  .banner-der,
  .sesenta,
  .treinta {
    width: 100%;
  }
  .fondo {
    width: 70%;
  }

  .formacion-dentro {
    align-items: center;
  }

  .subtitulo-izquierda,
  .sesenta p {
    text-align: center;
  }

  .menu-container {
    justify-content: center;
  }

  .tercio {
    width: 90%;
  }

  .compromiso-tercio,
  .empresas-dos {
    align-items: center;
    gap: 20px;
  }

  .img-treinta {
    max-width: 80%;
  }

  #compromiso .cincuenta {
    width: 100%;
  }

  .invertir-responsive {
    display: flex;
    flex-direction: column-reverse;
  }

  .derecha-texto {
    text-align: center;
  }

  .izquierda-texto {
    text-align: center;
  }
  .cuarenta {
    width: 70%;
  }

  .logo-footer {
    max-width: 80%;
  }
  .subtitulo-izquierda {
    font-size: 33px;
  }
  .nombre {
    font-size: 33px;
  }
  .subtitulo-grande {
    font-size: 33px;
  }
  .parrafo-sigueme {
    margin: 0 10px;
    font-size: 26px;
  }
  .redes-footer {
    max-width: 15%;
  }

  .presentacion {
    padding: 0;
    margin: 0;
    padding-left: 10px;
  }

  .header {
    align-items: center !important;
  }

  .izquierda-texto.parrafo-compromiso,
  .compromiso-dos .subtitulo.izquierda-texto {
    margin-right: 0px;
  }

  .banner {
    min-height: 0;
    padding: -20px 25px 35px 25px;
  }

  .banner-izq {
    min-height: 0;
  }

  .responsive {
    display: block;
  }

  .no-responsive {
    display: none;
  }

 .contenedor_propuestas{
  padding: 30px 10px 0 10px;
 }

  .titulopropuesta1{
    font-size: 28px;
    margin: 0;
  }
  .titulopropuesta2{
    font-size: 28px;
  }
  .contenedorpropuesta{
    flex-direction: column;
  }

  .contenedorpropuesta .cincuenta{
    width: 100%;
  }
}
