.carousel {
    position: relative;
    margin: auto;
    overflow: hidden;
  }
  
  .carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
    transform: translateX(calc(-100% * var(--current-index, 0)));
  }
  
  .carousel-image {
    flex: 0 0 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: absolute; /* Asegúrate de que las imágenes estén posicionadas correctamente */
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .carousel-image.active {
    opacity: 1; /* Solo la imagen activa será visible */
    position: relative; /* Reposiciona la imagen activa */
  }
  
  
  .carousel img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
  }
  
  .carousel-button.prev {
    left: 10px;
  }
  
  .carousel-button.next {
    right: 10px;
  }
  
  .carousel-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
