/* Estilos generales */
.menu {
  color: #fff;
  width: 100%;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-toggle {
  font-size: 1.5rem;
  background: none;
  color: #fff;
  border: none;
  cursor: pointer;
  display: none; /* Ocultar por defecto */
}

/* Estilos del menú */
.menu-links {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
}

.menu-links li {
  margin-left: 20px;
  display: flex  ;
  align-items: center;
  text-align: center;
}

.menu-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  font-weight: 600;
}

.menu-links a:hover {
  color: #ffb300;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .menu-toggle {
    display: block; /* Mostrar botón de hamburguesa */
  }

  .menu-links {
    flex-direction: column;
    display: none; /* Ocultar menú por defecto */
    position: absolute;
    top: 90px;
    left: 0;
    background-color: #00143d;
    width: 100%;
    padding: 10px 0;
    border: solid 1px;
  }

  .menu-links.open {
    display: flex; /* Mostrar menú cuando esté abierto */
    
    align-items: center;
  }

  .menu-links li {
    margin: 10px 0;
    text-align: center;
  }
  
  .menu-container {
    justify-content: center;
  }
}
